import { EuiFieldText, EuiLoadingSpinner } from "@elastic/eui";
import { useEffect, useState } from "react";

interface MMColumnFormattedProps {
  value: string;
  noWrap?: boolean;
  highlight?: string;
  isEditable?: boolean;
  placeholder?: any;
  onChange?: Function;
  isLoading?: boolean;
  isInvalid?: boolean;
}

function MMColumnFormatted(props: MMColumnFormattedProps) {
  const [value, setValue] = useState<string>(props.value);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleValueChange = (changedValue: string) => {
    if (props.onChange) {
      props.onChange(changedValue);
    } else {
      setValue(changedValue);
    }
    setIsEditing(false);
  };
  /* highlightPart and columnFormatted from production-list, should be changed into a component, or intergrated into MMCell */
  const highlightPart = (val: string, highlight: string) => {
    const lVal = val.toString().toLowerCase() as string;
    const lHighlight = highlight.toString().toLowerCase() as string;

    return lVal.indexOf(lHighlight) >= 0 ? (
      <span>
        {val.substring(0, lVal.indexOf(lHighlight))}
        <span style={{ backgroundColor: "#36786A44" }}>
          {val.substring(
            lVal.indexOf(lHighlight),
            lVal.indexOf(lHighlight) + lHighlight.length
          )}
        </span>
        <span>
          {val.substring(lVal.indexOf(lHighlight) + lHighlight.length)}
        </span>
      </span>
    ) : (
      val
    );
  };
  const columnFormatted = (
    value: string,
    noWrap: boolean = false,
    highlight?: string
  ) =>
    isEditing ? (
      <EuiFieldText
        isInvalid={props.isInvalid}
        autoFocus={true}
        compressed={true}
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onBlur={(e: any) => {
          handleValueChange(e.target.value);
        }}
      />
    ) : (
      <span
        id={props.isEditable ? "EDIT" : "READ"}
        style={
          noWrap
            ? {
                minWidth: props.isEditable ? "30px" : "0px",
                whiteSpace: "nowrap",
                cursor: props.isEditable ? "text" : "inherit",
                color: props.isInvalid ? "#ae3629" : "inherit",
              }
            : {
                minWidth: props.isEditable ? "30px" : "0px",
                cursor: props.isEditable ? "text" : "inherit",
                color: props.isInvalid ? "#ae3629" : "inherit",
              }
        }
        onClick={
          props.isEditable
            ? () => {
                setIsEditing(true);
              }
            : undefined
        }
      >
        {!!highlight
          ? highlightPart(value ? value.toString() : "", highlight)
          : value}
        {!value && props.isEditable
          ? props.placeholder
            ? props.placeholder
            : "-"
          : ""}
        {props.isLoading ? <EuiLoadingSpinner size="s" /> : <></>}
      </span>
    );

  return columnFormatted(value, props.noWrap, props.highlight);
}

export default MMColumnFormatted;
