import {
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from "@elastic/eui";
import { useEffect, useState } from "react";

import { MMOrderLineCostingFieldProps } from "../costing";
import {
  COSTING_EMPTY,
  Costing,
  OrderCostingType,
} from "store/data/costing/costing";
import { VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import { Order, isOrderLineDeclarationNeeded } from "store/data/order/order";
import { updateField } from "hoc/helper-hooks";
import txt from "helpers/text-helper";
import StringHelper from "helpers/string-helper";
import { InputValidator, ValidationResult } from "helpers/input-helper";
import { isCostingFirstWithMeasuringFee } from "../costing-inputs";

function MMCostingAmounts(props: MMOrderLineCostingFieldProps) {
  const [costing, setCosting] = useState<Costing>(
    props.costing || COSTING_EMPTY
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [hasValidation, setHasValidation] = useState<boolean>(false);

  const [vlotInvoiceDetails, setVlotInvoiceDetails] = useState<
    VlotInvoiceDetails | null | undefined
  >(props.vlotInvoiceDetails);
  const [orderLineIndex, setOrderLineIndex] = useState<number>(
    props.orderLineIndex
  );
  const [order, setOrder] = useState<Order | null | undefined>(props.order);
  const [isEditable, setIsEditable] = useState<boolean>(true);

  useEffect(() => {
    setCosting(props.costing || COSTING_EMPTY);
    setVlotInvoiceDetails(props.vlotInvoiceDetails);
    setIsEditable(props.isEditable);
    setOrderLineIndex(props.orderLineIndex);
    setHasValidation(props.hasValidation === true);
    if (props.hasValidation) {
      let newIsValid: boolean = false;
      if (
        props.inputs?.order_lines &&
        props.inputs.order_lines.length > props.orderLineIndex
      ) {
        let validator: InputValidator | undefined =
          props.inputs.order_lines[props.orderLineIndex].costing?.amount
            ?.validator;
        if (validator) {
          let result: ValidationResult = validator(props.costing?.amount);
          if (result.result) {
            newIsValid = true;
          }
        }
      }
      setIsValid(newIsValid);
    } else {
      setIsValid(true);
    }

    //props.inputs.order_lines[props.orderLineIndex].costing.amount.validator(props.costing.amount).result
  }, [
    props.costing,
    props.vlotInvoiceDetails,
    props.isEditable,
    props.orderLineIndex,
    props.inputs,
    props.hasValidation,
  ]);

  const renderResetButton = (
    value: string,
    field: string,
    type: "currency" | "boolean",
    orderLineProductIndex?: number,
    costing?: any,
    vlotInvoiceDetails?: VlotInvoiceDetails
  ) =>
    costing &&
    costing[value] !== null &&
    costing.contract &&
    costing[value] !== costing?.contract[field] ? (
      <EuiButtonEmpty
        isDisabled={!!vlotInvoiceDetails || !isEditable}
        color="warning"
        onClick={() => {
          props.onChange(
            updateField(
              costing || COSTING_EMPTY,
              value,
              costing?.contract[field]
            ),
            orderLineProductIndex !== undefined
              ? orderLineProductIndex
              : -1 /*hmmmm*/
          );
        }}
        size="xs"
        iconSide="right"
        iconType={!vlotInvoiceDetails && isEditable ? "editorUndo" : ""}
      >
        {type === "currency"
          ? StringHelper.currency(costing?.contract[field] || 0)
          : type === "boolean"
            ? txt.get(
                `generic.${
                  costing?.contract[field] === true
                    ? "yes"
                    : costing?.contract[field] === false
                      ? "no"
                      : "unknown"
                }`
              )
            : costing?.contract[field] + ""}
      </EuiButtonEmpty>
    ) : (
      <EuiButtonEmpty size="xs" style={{ opacity: 0, pointerEvents: "none" }} />
    );

  return (
    <EuiFlexGroup gutterSize="s" style={{ position: "relative", top: "4px" }}>
      <EuiFlexItem grow={false}>
        <EuiFormRow
          isInvalid={!isValid}
          display="rowCompressed"
          helpText={
            order?.costing?.type === OrderCostingType.InvoicePrivate ||
            (props.orderLineProduct?.orderType &&
              !isOrderLineDeclarationNeeded(
                props.orderLineProduct.orderType
              )) ? (
              <></>
            ) : (
              renderResetButton(
                "amount",
                "product_declaration_value",
                "currency",
                orderLineIndex,
                costing || undefined,
                vlotInvoiceDetails || undefined
              )
            )
          }
          label={
            order?.costing?.type === OrderCostingType.InvoicePrivate ||
            costing?.contract?.measuring_fee_first
              ? txt.get("orders.order.costing.amount_product")
              : txt.get("orders.order.costing.amount_combined")
          }
        >
          <EuiFieldNumber
            isInvalid={hasValidation && !isValid}
            disabled={!!vlotInvoiceDetails || !isEditable}
            style={{ width: "90px" }}
            compressed={true}
            // prepend={"€"}
            value={
              costing?.amount !== null && costing?.amount !== undefined
                ? costing.amount
                : ""
            }
            onChange={(e) =>
              setCosting(
                updateField(
                  costing,
                  "amount",
                  e.target.value ? parseFloat(e.target.value) : null
                )
              )
            }
            onBlur={(e) =>
              props.onChange(
                updateField(
                  costing,
                  "amount",
                  e.target.value ? parseFloat(e.target.value) : null
                ),
                orderLineIndex !== undefined ? orderLineIndex : -1 /*hmmmm*/
              )
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
      {order?.costing?.type === OrderCostingType.InvoicePrivate ||
      costing?.contract?.measuring_fee_first ? (
        <EuiFlexItem grow={false}>
          <EuiFormRow
            display="rowCompressed"
            helpText={
              order?.costing?.type === OrderCostingType.InvoicePrivate ||
              (props.orderLineProduct?.orderType &&
                !isOrderLineDeclarationNeeded(
                  props.orderLineProduct.orderType
                )) ? (
                <></>
              ) : (
                renderResetButton(
                  "amount_effort",
                  isCostingFirstWithMeasuringFee(order, orderLineIndex)
                    ? "measuring_fee_first"
                    : "measuring_fee_consecutive",
                  "currency",

                  orderLineIndex,
                  costing || undefined,
                  vlotInvoiceDetails || undefined
                )
              )
            }
            label={txt.get("orders.order.costing.amount_effort")}
          >
            <EuiFieldNumber
              disabled={!!vlotInvoiceDetails || !isEditable}
              style={{ width: "90px" }}
              compressed={true}
              // prepend={"€"}
              value={
                costing?.amount_effort !== null &&
                costing?.amount_effort !== undefined
                  ? costing?.amount_effort
                  : ""
              }
              onChange={(e) =>
                setCosting(
                  updateField(
                    costing || COSTING_EMPTY,
                    "amount_effort",
                    e.target.value ? parseFloat(e.target.value) : null
                  )
                )
              }
              onBlur={(e) =>
                props.onChange(
                  updateField(
                    costing || COSTING_EMPTY,
                    "amount_effort",
                    e.target.value ? parseFloat(e.target.value) : null
                  ),
                  orderLineIndex !== undefined ? orderLineIndex : -1 /*hmmmm*/
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMCostingAmounts;
