import {
  copyToClipboard,
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiButtonGroup,
  EuiCard,
  EuiComboBox,
  EuiDescriptionList,
  EuiFieldSearch,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiSuperSelect,
  EuiSwitch,
  EuiText,
  OnTimeChangeProps,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import { useSearchParams } from "react-router-dom";
import StringHelper from "helpers/string-helper";
import {
  Order,
  OrderLine,
  orderLineMeasurementsDescription,
  orderLineStatusDescription,
  orderLineToHealth,
  OrderStatus,
  orderStatusDescription,
  orderToHealth,
  productDescription,
  productShortDescription,
} from "store/data/order/order";

import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";
import datemath from "@elastic/datemath";
import UrlHelper from "helpers/url-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { useDispatch, useSelector } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import txt from "helpers/text-helper";
import {
  AlertConfirm,
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { formatLocation } from "store/data/location/location";
import { v4 as uuid } from "uuid";
import { Organisation } from "store/data/organisation/organisation";
import {
  formalName,
  fullName,
  PersonalDetails,
} from "store/data/personal-details/personal-details";
import { columnDateShort } from "hoc/helper-hooks";
import {
  Referral,
  referralMissingText,
  ReferralStatus,
  referralStatusDescription,
  referralToHealth,
} from "store/data/referral/referral";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import { Referrer } from "store/data/referrer/referrer";
import MMCell from "components/layouts/table/cell";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const STATUS_DEBOUNCE_DELAY = 1400;
export const DEFAULT_SINCE = "2020-01-01";
export const DEFAULT_UNTIL = "now";
export const DEFAULT_SORT_BY = "ordered_at";
export const DEFAULT_SORT_ORDER = "desc";
export const DEFAULT_MY_ORDERS_SELECTED = false;
export const DEFAULT_WITH_REFERRAL = "any";

function MMOrdersList() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOrderNavigate = (orderId: string | number) => {
    let params = UrlHelper.queryParams();
    params.order = orderId;
    setSearchParams(params, { replace: true });
  };

  const api = new ConnectAPIHelper();

  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m"]);

  const [columnsToShow, setColumnsToShow] = useState<any[]>([]);
  const [actionsToShow, setActionsToShow] = useState<any[]>([]);
  const [canDelete, setCanDelete] = useState(false);

  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState<[]>([]);
  const [since, setSince] = useLocalStorage("order_since", DEFAULT_SINCE);
  const [until, setUntil] = useLocalStorage("order_until", DEFAULT_UNTIL);

  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "order_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [selectedReferralStatusOptions, setSelectedReferralStatusOptions] =
    useLocalStorage("selected_referral_status", []);

  const [referralStatus] = useDebounce(
    selectedReferralStatusOptions,
    STATUS_DEBOUNCE_DELAY
  );

  const [selectedOrderStatusOptions, setSelectedOrderStatusOptions] =
    useLocalStorage("selected_order_status", []);

  const [orderStatus] = useDebounce(
    selectedOrderStatusOptions,
    STATUS_DEBOUNCE_DELAY
  );
  const [organisationId, setOrganisationId] = useLocalStorage(
    "order_organisation",
    "_"
  );
  const [practitionerId, setPractitionerId] = useLocalStorage(
    "order_practitioner",
    "_"
  );
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [practitioners, setPractitioners] = useState<PersonalDetails[]>([]);

  const [limit, setLimit] = useLocalStorage("order_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("order_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("order_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage(
    "order_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [isLoading, setIsLoading] = useState(false);
  const [expandedOrders, setExpandedOrders] = useState({} as any);
  const [error, setError] = useState("");
  const [isMyOrdersSelected, setIsMyOrdersSelected] = useLocalStorage(
    "order_my_orders",
    DEFAULT_MY_ORDERS_SELECTED
  );
  const [withReferral, setWithReferral] = useLocalStorage(
    "order_with_referral",
    DEFAULT_WITH_REFERRAL
  );
  const [deleteRef] = useState("delete_orders_" + uuid());

  const getOrganisation = (organisationId: number) => {
    return organisations.find(
      (organisation: Organisation) => organisation.id === organisationId
    );
  };

  useEffect(() => {
    const loadOrganisations = async () => {
      const organisations = await api.getOrganisations();
      // if (
      //   !organisationId ||
      //   organisationId === "_" &&
      //   organisations.findIndex(
      //     (organisation: Organisation) => organisation.id === organisationId
      //   ) < 0
      // ) {
      //   let userOrg: any = AuthenticationHelper.getOrganisationId();
      //   console.log("loadOrganisations===== set to users org", userOrg);
      //   setOrganisationId(userOrg + "");
      // } else {
      //   console.log(
      //     "loadOrganisations===== set to org again..",
      //     organisationId
      //   );
      //   setOrganisationId(organisationId + "");
      // }
      setOrganisations(organisations);
    };

    loadOrganisations();
  }, []);

  useEffect(() => {
    const loadPractitioners = async () => {
      console.log("loadPractitioners=====", organisationId);
      const practitioners: PersonalDetails[] = await api.getPractitioners(
        organisationId === "_" ? undefined : organisationId
      );
      // if (
      //   practitionerId &&
      //   practitionerId !== "_" &&
      //   practitioners.findIndex(
      //     (practitioner: PersonalDetails) => practitioner.id === practitionerId
      //   ) < 0
      // ) {
      //   console.log("reset practitioners");
      //   setPractitionerId("_");
      // } else {
      //   setPractitionerId(practitionerId + "");
      // }
      if (practitioners && practitioners.sort) {
        practitioners.sort((a: PersonalDetails, b: PersonalDetails) =>
          `${a.first_name}${a.last_name}` < `${b.first_name}${b.last_name}`
            ? -1
            : `${a.first_name}${a.last_name}` > `${b.first_name}${b.last_name}`
              ? 1
              : 0
        );
      }
      setPractitioners(practitioners);
    };

    loadPractitioners();
  }, [organisationId]);

  const getColumnsToShow = () => {
    let result: any[] = [];

    columnsToShow.forEach((column) => {
      switch (column) {
        case "products":
          result.push({
            name: txt.get("orders.order.products"),
            truncateText: true,
            render: (order: Order) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  toggleOrderLines(order);
                }}
              >
                <EuiIcon
                  style={{ position: "relative", top: "2px" }}
                  type={expandedOrders[order.id] ? "arrowDown" : "arrowRight"}
                />
                <EuiText size="s">
                  {order.order_lines
                    .filter((line: OrderLine, i: number) => i < 1)
                    .map((line: OrderLine) => line)
                    .reduce(
                      (descriptions: string, line: any) =>
                        `${descriptions} ${
                          line.product_selection
                            ? productShortDescription(
                                line.product_selection,
                                line.translations
                              )
                            : ""
                        }`,
                      `${
                        order.order_lines.length === 1
                          ? `1 ${txt.lo("orders.order.product")}`
                          : txt.lo(
                              "orders.order.count_products",
                              order.order_lines.length
                            )
                      } :`
                    ) + (order.order_lines.length > 1 ? "..." : "")}
                </EuiText>
              </div>
            ),
          });
          break;
        case "order_id":
          result.push({
            name: txt.get("orders.order.id"),
            field: "id",
            render: (id: string, order: Order) => (
              <Fragment>
                <MMColumnFormatted
                  value={id}
                  noWrap={false}
                  highlight={search}
                />
                {order.is_demo ? (
                  <EuiBadge
                    style={{
                      marginLeft: "3px",
                      fontSize: "8px",
                      // position: "relative",
                      // top: "-3px",
                    }}
                    color="accent"
                  >
                    {txt.up("generic.demo")}
                  </EuiBadge>
                ) : (
                  <></>
                )}
              </Fragment>
            ),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("id")),
          });
          break;
        case "ordered_at":
          result.push({
            name: txt.get("orders.order.ordered_at"),
            field: "ordered_at",
            type: "date",
            sortable: api.orderIsSortableBy(orderFieldToSortKey("ordered_at")),
            render: columnDateShort,
          });
          break;
        case "practitioner":
          result.push({
            name: txt.get("orders.order.practitioner"),
            field: "personal_details",
            sortable: api.orderIsSortableBy(
              orderFieldToSortKey("practitioner")
            ),
            render: (personal_details: any) =>
              personal_details ? (
                <MMColumnFormatted
                  value={`${personal_details.first_name} ${personal_details.last_name}`}
                  noWrap={false}
                  highlight={search}
                />
              ) : (
                "-"
              ),
          });
          break;
        case "client_code":
          result.push({
            name: txt.get("orders.order.client_code"),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("client_code")),
            field: "client_code",
            render: (clientCode: string, order: Order) => (
              <Fragment>
                {order.client_code ? (
                  order.client_code === search ? (
                    <MMColumnFormatted
                      value={order.client_code}
                      noWrap={false}
                      highlight={search}
                    />
                  ) : (
                    order.client_code
                  )
                ) : (
                  "-"
                )}
                {AuthenticationHelper.isInternalUser() &&
                order.external_client_reference ? (
                  <EuiText size="xs" style={{ marginLeft: "0.5em" }}>
                    ({getOrganisation(parseInt(order.organisation_id))?.acronym}
                    )
                    {order.external_client_reference === search ? (
                      <MMColumnFormatted
                        value={order.external_client_reference}
                        noWrap={false}
                        highlight={search}
                      />
                    ) : (
                      order.external_client_reference
                    )}
                  </EuiText>
                ) : (
                  <></>
                )}
              </Fragment>
            ),
          });
          break;
        case "delivery_method":
          result.push({
            name: txt.get("orders.order.delivery"),
            sortable: api.orderIsSortableBy(
              orderFieldToSortKey("delivery_method")
            ),
            field: "delivery_method",
            render: (delivery_method: string) =>
              delivery_method
                ? StringHelper.ucfirst(delivery_method ?? "-")
                : "-",
          });

          break;
        case "external_client_reference":
          //todo: the reference/patient_number should be based on the organisation data
          result.push({
            name: txt.get(
              AuthenticationHelper.hasRole("b2b")
                ? "orders.external_client_reference_name.reference"
                : "orders.external_client_reference_name.patient_number"
            ),
            sortable: api.orderIsSortableBy(
              orderFieldToSortKey("external_client_reference")
            ),
            field: "external_client_reference",
            render: (external_client_reference: string) =>
              external_client_reference ? external_client_reference : "-",
          });
          break;
        case "external_client_name":
          result.push({
            name: txt.get("orders.order.client_name"),
            sortable: api.orderIsSortableBy(
              orderFieldToSortKey("external_client_name")
            ),
            field: "external_client_name",
            render: (external_client_name: string) =>
              external_client_name ? external_client_name : "-",
          });
          break;
        case "delivery_location":
          result.push({
            name: txt.get("orders.order.delivery_location"),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("location")),
            field: "location",
            render: (location: Location) =>
              location ? (
                <MMColumnFormatted
                  value={formatLocation(location ?? "-")}
                  noWrap={false}
                  highlight={search}
                />
              ) : (
                "-"
              ),
          });
          break;
        case "ordered_by":
          result.push({
            name: txt.get("orders.order.ordered_by"),
            field: "ordered_by",
            type: "string",
            render: (ordered_by: string) => (
              <MMColumnFormatted
                value={ordered_by}
                noWrap={false}
                highlight={search}
              />
            ),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("ordered_by")),
          });
          break;
        case "referral":
          result.push({
            name: txt.get("referrals.name"),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("referral")),
            field: "referral",
            render: (referral: Referral) =>
              referral ? (
                <EuiFlexGroup direction="column" gutterSize="xs">
                  <EuiFlexItem style={{ display: "inline-block" }}>
                    <EuiBadge
                      color={referralToHealth(
                        referral.status as ReferralStatus
                      )}
                    >
                      {referralStatusDescription(
                        referral.status as ReferralStatus
                      )}
                    </EuiBadge>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {!referral.status ||
                    referral.status === ReferralStatus.Pending ? (
                      <EuiText size="s">
                        {referralMissingText(referral)}
                      </EuiText>
                    ) : (
                      <></>
                    )}{" "}
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : (
                <EuiText size="xs">-</EuiText>
              ),
          });

          break;
        case "referrer":
          result.push({
            name: txt.get("admin.referrers.name"),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("referrer")),
            field: "referral.referrer",
            render: (referrer: Referrer) =>
              referrer && referrer.person ? (
                <MMCell
                  wrap={false}
                  text={formalName(referrer.person)}
                  subSoft={true}
                  subText={
                    referrer.alternative_organisation
                      ? referrer.alternative_organisation
                      : referrer.organisation
                        ? referrer.organisation.name
                        : "-"
                  }
                />
              ) : (
                "-"
              ),
          });

          break;
        // case "evaluation":
        //   result.push({
        //     name: txt.get("orders.order.score"),
        //     sortable: false,
        //     field: "evaluations",
        //     render: (evaluations: Evaluation[]) => {
        //       if (evaluations && evaluations.length > 0) {
        //         const score: number = evaluations[evaluations.length - 1].score;
        //         return (
        //           <EuiToolTip
        //             content={
        //               evaluations[evaluations.length - 1].notes ||
        //               txt.get("orders.order.no_evaluation_notes")
        //             }
        //           >
        //             <EuiText size="xs">
        //               <span style={{ position: "relative", top: "-2px" }}>
        //                 <EuiIcon
        //                   size="s"
        //                   type={
        //                     score <= 6
        //                       ? "faceSad"
        //                       : score <= 8
        //                       ? "faceNeutral"
        //                       : score >= 9
        //                       ? "faceHappy"
        //                       : "question"
        //                   }
        //                 />
        //               </span>
        //               &nbsp;
        //               {evaluations[evaluations.length - 1].score}
        //             </EuiText>
        //           </EuiToolTip>
        //         );
        //       }
        //       return "-";
        //     },
        //   });
        //   break;
        case "status":
          result.push({
            name: txt.get("generic.status"),
            sortable: api.orderIsSortableBy(orderFieldToSortKey("status")),
            field: "status",
            render: (status: OrderStatus) => {
              return (
                <Fragment>
                  <EuiBadge color={orderToHealth(status as OrderStatus)}>
                    {orderStatusDescription(status as OrderStatus)}
                  </EuiBadge>
                </Fragment>
              );
            },
          });
          break;
        case "actions":
          result.push({
            name: "",
            isExpander: true,
            actions: getActionsToShow(),
          });
      }
    });
    return result;
  };

  const getActionsToShow = () => {
    let result: any[] = [];

    actionsToShow.forEach((action) => {
      switch (action) {
        case "read":
          result.push({
            render: (order: Order) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    handleOrderNavigate(order.id);
                  }}
                >
                  <EuiIcon
                    cursor="pointer"
                    type={
                      order.status == OrderStatus.Drafted ? "pencil" : "eye"
                    }
                  />
                </div>
              );
            },
          });
          break;
        case "copy":
          result.push({
            render: (order: Order) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    duplicateOrder(order.id);
                  }}
                >
                  <EuiIcon cursor="pointer" type="copy" />
                </div>
              );
            },
          });
          break;
      }
    });
    return result;
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let actions: any[] = [];

      if (
        await AuthenticationHelper.hasPermission([
          "orders#read",
          "orders#read_org",
          "orders#read_all",
        ])
      ) {
        actions.push("read");
      }

      // if (
      //   await AuthenticationHelper.hasPermission([
      //     "orders#submit",
      //     "orders#submit_org",
      //     "orders#submit_all",
      //   ])
      // ) {
      //   actions.push("copy");
      // }

      setActionsToShow(actions);

      let columns: any[] = [];

      columns.push("products");
      columns.push("order_id");
      columns.push("ordered_at");
      columns.push("practitioner");
      if (AuthenticationHelper.isInternalUser()) {
        columns.push("client_code");
        columns.push("delivery_method");
      } else {
        columns.push("external_client_reference");
      }

      if (
        !AuthenticationHelper.isInternalUser() &&
        !AuthenticationHelper.getGroups().includes("/b2b") &&
        !AuthenticationHelper.getGroups().includes("the-hand-clinic")
      ) {
        columns.push("external_client_name");
      }

      columns.push("delivery_location");

      if (AuthenticationHelper.isInternalUser()) {
        columns.push("ordered_by");
        columns.push("referral");
        columns.push("referrer");
        // columns.push("evaluation");
      }

      columns.push("status");
      columns.push("actions");

      setColumnsToShow(columns);

      setCanDelete(
        await AuthenticationHelper.hasPermission("orders#delete_all")
      );
    };

    setInterfaceForPermissions();
  }, [organisations]);

  useEffect(() => {
    const querySearch = UrlHelper.queryParam("search");
    const queryStatus = UrlHelper.queryParam("status");
    const queryReferralStatus = UrlHelper.queryParam("referral_status");

    const queryOrganisation = UrlHelper.queryParam("organisation");
    const queryMe = UrlHelper.queryParam("me");

    if (querySearch || queryStatus || queryMe || queryOrganisation) {
      setUntil(DEFAULT_UNTIL);
      setSince(DEFAULT_SINCE);
      setSortBy(DEFAULT_SORT_BY);
      setSortOrder(DEFAULT_SORT_ORDER);
      setWithReferral(DEFAULT_WITH_REFERRAL);
      setSearchFieldValue(querySearch ?? "");
      setOrganisationId(queryOrganisation ?? "_");
      setIsMyOrdersSelected(queryMe || DEFAULT_MY_ORDERS_SELECTED);
    } else {
      // setSearchFieldValue("");
      // setOrderStatusValue("_");
      // setIsMyOrdersSelected(DEFAULT_MY_ORDERS_SELECTED);
      // setOrganisationId("_");
    }
  }, []);

  const duplicateOrder = async (orderId: number) => {
    setIsLoading(true);

    const result = await api.duplicateOrder(orderId);
    console.log("duplicateOrder", result);
    if (result && result.order_id) {
      setUntil(DEFAULT_UNTIL);
      setSince(DEFAULT_SINCE);
      setSortBy(DEFAULT_SORT_BY);
      setSortOrder(DEFAULT_SORT_ORDER);
      setWithReferral(DEFAULT_WITH_REFERRAL);
      dispatch(
        toastAdd(
          txt.get("generic.is_duplicated", txt.get("orders.order.name")),
          null,
          "success"
        )
      );
      handleOrderNavigate(result.order_id);
    } else {
      dispatch(toastAdd(txt.get("generic.error"), null, "danger"));
    }

    setIsLoading(false);
  };

  const loadOrders = async () => {
    setExpandedOrders({});
    setIsLoading(true);

    let sinceDate = since ? datemath.parse(since)?.toDate() : undefined;
    let untilDate = until
      ? datemath.parse(until, { roundUp: true })?.toDate()
      : undefined;

    let filters: any = {
      ordered_by: isMyOrdersSelected ? AuthenticationHelper.getEmail() : "",
      search: search.toUpperCase().trim(),
    };

    if (AuthenticationHelper.isInternalUser()) {
      filters.with_referral = withReferral;

      if (orderStatus && orderStatus.length > 0) {
        filters.status = orderStatus.map((status: any) => status.value);
      }
      if (referralStatus && referralStatus.length > 0) {
        filters.referral_status = referralStatus.map(
          (status: any) => status.value
        );
      }
      if (organisationId && organisationId !== "_") {
        filters.organisation_id = parseInt(organisationId);
      }
    }
    if (practitionerId && practitionerId !== "_") {
      filters.practitioner = parseInt(practitionerId);
    }

    const result = await api.getOrders(
      filters,
      limit,
      offset,
      orderFieldToSortKey(sortBy),
      sortOrder
      // don't need it for now, but not sure how to remove it safely before
      // sinceDate,
      // untilDate
    );

    setIsLoading(false);
    if (result.status === "OK") {
      setOrders(result.result);
      setTotal(result.meta_data.result_set.total);
      setError("");
    } else {
      setOrders([]);
      setTotal(0);
      setError(`${result.status} (${result.code}): ${result.message}`);
    }
  };

  useEffect(() => {
    loadOrders();
  }, [
    since,
    until,
    search,
    orderStatus,
    referralStatus,
    limit,
    offset,
    sortBy,
    sortOrder,
    isMyOrdersSelected,
    withReferral,
    organisationId,
    practitionerId,
  ]);

  const alertConfirm: AlertConfirm = useSelector(confirmGet);
  const handleDeleteOrders = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.page_title"))}.`,
        txt.get("orders.delete_orders_confirm", selectedOrders.length),
        deleteRef
      )
    );
  };

  useEffect(() => {
    if (
      alertConfirm.actionState == AlertConfirmActionState.Perform &&
      alertConfirm.actionKey == deleteRef
    ) {
      deleteOrders();
    }
  }, [alertConfirm]);

  const deleteOrders = async () => {
    if (selectedOrders.length > 0) {
      const result = await api.deleteOrders(
        selectedOrders.map((order: Order) => order.id)
      );
      dispatch(
        toastAdd(
          txt.get("orders.deleted_orders", result.deleted_order_count),
          null,
          "success"
        )
      );
    }
    loadOrders();
  };

  const orderFieldToSortKey = (field: string) => {
    switch (field) {
      // case 'client_hand':
      //   return 'hand';
      // case 'client_number':
      //   return 'client';
      default:
        return field;
    }
  };

  const onTimeChange = ({ start, end }: OnTimeChangeProps) => {
    setSince(start);
    setUntil(end);
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const onReferralStatusChange = (selected: any) => {
    setSelectedReferralStatusOptions(selected);
  };

  const onOrderStatusChange = (selected: any) => {
    setSelectedOrderStatusOptions(selected);
  };

  const onOrganisationSuperChange = (organisationId: number) => {
    setOrganisationId(organisationId.toString());
    setPractitionerId("_");
  };

  const onPractitionerSuperChange = (practitionerId: number) => {
    setPractitionerId(practitionerId.toString());
  };

  const onOrdersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("orders.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("orders.page_title")
        ) + ".";

  const organisationsToSuperSelectOptions = (organisations: Organisation[]) => {
    let result: any[] = [];

    if (organisations && organisations.length > 1) {
      result.push({
        inputDisplay: txt.get("generic.any_x", txt.lo("account.organisation")),
        value: "_",
      });
    }

    if (organisations && organisations.forEach) {
      organisations.forEach((organisation: Organisation) => {
        result.push({
          inputDisplay: organisation.name,
          value: organisation.id?.toString(),
        });
      });
    }

    return result;
  };

  const practitionersToSuperSelectOptions = (
    practitioners: PersonalDetails[]
  ) => {
    let result: any[] = [];

    if (practitioners && practitioners.length > 0) {
      result.push({
        inputDisplay: txt.get(
          "generic.any_x",
          organisations.length > 1 && organisationId && organisationId !== "_"
            ? `${getOrganisation(parseInt(organisationId))?.name} ${txt.lo(
                "orders.order.practitioner"
              )}`
            : txt.lo("orders.order.practitioner")
        ),
        value: "_",
      });
    }

    if (practitioners) {
      practitioners.forEach((practitioner: PersonalDetails) => {
        result.push({
          inputDisplay: fullName(practitioner),
          value: practitioner.id ? practitioner.id.toString() : "",
        });
      });
    }

    return result;
  };

  const referralStatusEnumToOptions = (enumeration: any) => {
    let result = enumToSelectOptions(enumeration);
    result = result.map((element: any, i: number) => ({
      value: element.value,
      label: referralStatusDescription(element.text),
    }));
    return result;
  };

  const orderStatusEnumToOptions = (enumeration: any) => {
    let result = enumToSelectOptions(enumeration);
    result = result.map((element: any, i: number) => ({
      value: element.value,
      label: orderStatusDescription(element.text),
    }));

    return [
      {
        label: txt.get("orders.order.status_group.intake"),
        options: result.filter((res: any) =>
          [
            OrderStatus.Drafted,
            OrderStatus.Submitted,
            OrderStatus.Accepted,
          ].includes(res.value)
        ),
      },
      {
        label: txt.get("orders.order.status_group.production"),
        options: result.filter((res: any) =>
          [
            OrderStatus.ProducedPartial,
            OrderStatus.Produced,
            OrderStatus.PackagedPartial,
            OrderStatus.Packaged,
          ].includes(res.value)
        ),
      },
      {
        label: txt.get("orders.order.status_group.shipping"),
        options: result.filter((res: any) =>
          [
            OrderStatus.RoutedPartial,
            OrderStatus.RoutedPartial,
            OrderStatus.ShippedPartial,
            OrderStatus.Shipped,
            OrderStatus.ReturnedPartial,
            OrderStatus.Returned,
          ].includes(res.value)
        ),
      },
      {
        label: txt.get("orders.order.status_group.delivery"),
        options: result.filter((res: any) =>
          [OrderStatus.DeliveredPartial, OrderStatus.Delivered].includes(
            res.value
          )
        ),
      },
      {
        label: txt.get("orders.order.status_group.finish"),
        options: result.filter((res: any) =>
          [
            OrderStatus.Finalized,
            OrderStatus.Cancelled,
            OrderStatus.Rejected,
          ].includes(res.value)
        ),
      },
    ];
  };

  const enumToSelectOptions = (enumeration: any) => {
    let result: any = [];

    Object.entries(enumeration).forEach(([text, value]) => {
      text = text.replace(/([A-Z])/g, "_$1").replace("_", "");
      result.push({ value, text });
    });

    return result;
  };

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const isOrderValid = (order: Order) =>
    order.legacy_data.validation &&
    order.legacy_data.validation.header.error == false &&
    order.legacy_data.validation.lines.error == false;

  const toggleOrderLines = (order: Order) => {
    let newExpandedOrders: any = { ...expandedOrders };
    console.log("toggleOrderLines", order.id, newExpandedOrders);
    if (newExpandedOrders[order.id.toString()]) {
      console.log("delete existing");
      delete newExpandedOrders[order.id.toString()];
    } else {
      let listItems: any = [];
      order.order_lines.forEach((line) => {
        listItems.push({
          title: "Product",
          description: `${line.code}-${line.hand}`,
        });
      });

      newExpandedOrders[order.id.toString()] = (
        <EuiFlexGrid
          columns={isMobileScreen ? 1 : isSmallScreen ? 3 : 4}
          gutterSize="s"
        >
          {order.order_lines.map((line: OrderLine) => {
            let descriptionLines = [
              {
                title: txt.get("orders.order.order_type"),
                description: line.order_type ?? "-",
              },
              {
                title: txt.get("orders.order.hand"),
                description: `${
                  line.hand && line.hand.toString() === "L"
                    ? "Left"
                    : line.hand && line.hand.toString() === "R"
                      ? "Right"
                      : ""
                } ${
                  line.digits && line.digits.length
                    ? " (" + line.digits.join(", ") + ")"
                    : ""
                }`,
              },
              {
                title: txt.get("orders.order.measurements"),
                description: line.measurements
                  ? orderLineMeasurementsDescription(line.measurements)
                  : "-",
              },
            ];
            if (line.remake_reason || line.replacement_for) {
              descriptionLines.push({
                title: txt.get("orders.order.reason"),
                description: line.remake_reason ?? "-",
              });
              descriptionLines.push({
                title: txt.get("orders.order.replaces_product"),
                description: line.replacement_for ?? "-",
              });
            }
            return (
              <EuiFlexItem key={`key-${line.id}`}>
                <EuiCard
                  textAlign="left"
                  title={
                    line.product_selection
                      ? productDescription(
                          line.product_selection,
                          line.translations
                        )
                      : ""
                  }
                  children={
                    <EuiDescriptionList
                      type="column"
                      rowGutterSize="s"
                      listItems={descriptionLines}
                    />
                  }
                  footer={
                    <Fragment>
                      {line.measurements && line.measurements.notes ? (
                        <EuiDescriptionList
                          rowGutterSize="s"
                          listItems={[
                            {
                              title: txt.get("orders.order.notes"),
                              description: line.measurements.notes ?? "-",
                            },
                          ]}
                        />
                      ) : (
                        <></>
                      )}
                      <EuiBadge
                        color={orderLineToHealth(line.status)}
                        title={line.status}
                      >
                        {orderLineStatusDescription(line.status)}
                      </EuiBadge>
                    </Fragment>
                  }
                />
              </EuiFlexItem>
            );
          })}
        </EuiFlexGrid>
      );
    }
    console.log("setting", newExpandedOrders);
    setExpandedOrders(newExpandedOrders);
  };

  const getRowProps = (order: any) => {
    const { id } = order;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        e.stopPropagation();
        if (e.shiftKey) {
          copyToClipboard(e.target.innerText);
          dispatch(
            toastAdd(txt.get("generic.copied_x", `'${e.target.innerText}'`))
          );
        } else if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          handleOrderNavigate(id);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="center" justifyContent="flexStart">
        <EuiFlexItem style={{ flexGrow: 1 }}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        <EuiFlexItem
          style={{
            flexGrow: 1,
            display:
              organisations && organisations.length > 1 ? "flex" : "none",
          }}
        >
          <EuiSuperSelect
            compressed={true}
            id="order-organisation-select"
            isLoading={isLoading}
            placeholder={txt.get(
              "generic.select_x",
              txt.get("account.organisation")
            )}
            options={organisationsToSuperSelectOptions(organisations)}
            valueOfSelected={organisationId}
            onChange={(organisationId) =>
              onOrganisationSuperChange(organisationId)
            }
            aria-label={txt.get(
              "generic.select_x",
              txt.get("account.organisation")
            )}
          />
        </EuiFlexItem>
        <EuiFlexItem
          style={{
            flexGrow: 1,
            display:
              practitioners && practitioners.length > 0 ? "flex" : "none",
          }}
        >
          <EuiSuperSelect
            compressed={true}
            id="order-practitioner-select"
            isLoading={isLoading}
            placeholder={txt.get(
              "generic.select_x",
              txt.get("order.order.practitioner")
            )}
            options={practitionersToSuperSelectOptions(
              practitioners && practitioners.length > 0 ? practitioners : []
            )}
            valueOfSelected={practitionerId}
            onChange={(practitionerId) =>
              onPractitionerSuperChange(practitionerId)
            }
            aria-label={txt.get(
              "generic.select_x",
              txt.get("orders.order.practitioner")
            )}
          />
        </EuiFlexItem>

        <EuiFlexItem style={{ flexGrow: 1 }}>
          {AuthenticationHelper.isInternalUser() ? (
            <EuiComboBox
              compressed={true}
              id="referral-status-select"
              isLoading={isLoading}
              aria-label={txt.get(
                "generic.select_x",
                txt.get("referrals.referral_status")
              )}
              placeholder={txt.uf(
                "generic.select_x",
                txt.get("referrals.referral_status")
              )}
              // singleSelection={{ asPlainText: true }}
              options={referralStatusEnumToOptions(ReferralStatus)}
              selectedOptions={selectedReferralStatusOptions}
              onChange={(selected) => onReferralStatusChange(selected)}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          {AuthenticationHelper.isInternalUser() ? (
            <EuiButtonGroup
              legend="referral selection"
              idSelected={withReferral}
              onChange={(key) => setWithReferral(key)}
              type="single"
              buttonSize="compressed"
              options={[
                {
                  id: "with",
                  label: txt.get("generic.with"),
                },
                {
                  id: "without",
                  label: txt.get("generic.without"),
                },
                { id: "any", label: txt.get("generic.both") },
              ]}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
        <EuiFlexItem style={{ flexGrow: 1 }}>
          {AuthenticationHelper.isInternalUser() ? (
            <EuiComboBox
              compressed={true}
              id="order-status-select"
              isLoading={isLoading}
              aria-label={txt.get(
                "generic.select_x",
                txt.get("generic.status")
              )}
              placeholder={txt.uf(
                "generic.select_x",
                txt.get("generic.status")
              )}
              // singleSelection={{ asPlainText: true }}
              options={orderStatusEnumToOptions(OrderStatus)}
              selectedOptions={selectedOrderStatusOptions}
              onChange={(selected) => onOrderStatusChange(selected)}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem style={{ flexGrow: 1 }}>
        <EuiSpacer size="m" />
        <EuiSwitch
          disabled={isLoading}
          onChange={() => setIsMyOrdersSelected(!isMyOrdersSelected)}
          label={txt.get("orders.only_me")}
          checked={isMyOrdersSelected}
        />
      </EuiFlexItem>
      <EuiSpacer size="l" />
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText>
            {canDelete ? (
              <EuiButton
                color="danger"
                iconType="cross"
                onClick={() => {
                  handleDeleteOrders();
                }}
                isDisabled={selectedOrders.length === 0}
                size="s"
              >
                {" "}
                {txt.uf(
                  "generic.delete_x",
                  txt.get("generic.selected_x", txt.get("orders.page_title"))
                )}
              </EuiButton>
            ) : null}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        tableLayout="auto"
        itemId="id"
        itemIdToExpandedRowMap={expandedOrders}
        selection={
          canDelete
            ? {
                onSelectionChange: (selectedOrders: any) => {
                  setSelectedOrders(selectedOrders);
                },
              }
            : undefined
        }
        items={orders}
        columns={getColumnsToShow()}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf("generic.found_no_x", txt.get("orders.page_title"))
        }
        onChange={onOrdersChange}
      />
    </Fragment>
  );
}

export default MMOrdersList;
