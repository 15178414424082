import {
  EuiButtonEmpty,
  EuiCheckbox,
  EuiDatePicker,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSuperSelect,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { ORDER_EMPTY, OrderStatus } from "store/data/order/order";
import { Fragment, useEffect, useState } from "react";
import { MMOrderEntryInputProps } from "../order-entry";
import MomentHelper from "helpers/moment-helper";
import { updateField } from "hoc/helper-hooks";
import { REFERRAL_EMPTY, Referral } from "store/data/referral/referral";
import { getOrderInput } from "../order-inputs";
import { Input } from "helpers/input-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import { formalName } from "store/data/personal-details/personal-details";
import { indicationDescription } from "store/data/indication/indication";
import { Moment } from "moment";
import MMTip from "components/layouts/tip/tip";

function MMOrderIntake(props: MMOrderEntryInputProps) {
  const [referral, setReferral] = useState(props.order?.referral || undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [clientReferrals, setClientReferrals] = useState<Referral[]>(
    props.clientReferrals || []
  );
  const [clientReferralOptions, setClientReferralOptions] = useState<any[]>([]);
  const [selectedClientReferralOption, setSelectedClientReferralOption] =
    useState<any>(
      props.order?.referral_id ? props.order.referral_id + "" : "_"
    );

  useEffect(() => {
    setReferral(props.order?.referral || undefined);

    // const setInterfaceForPermissions = async () => {
    // };

    // setInterfaceForPermissions();
  }, [props.order]);

  const clearReferral = () => {
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(props.order || ORDER_EMPTY, "referral", REFERRAL_EMPTY),
          "referral_id",
          null
        )
      );
    } else {
      setReferral(REFERRAL_EMPTY);
    }
    setSelectedClientReferralOption("_");
  };
  const handleIntakeDate = (referral: Referral): Referral => {
    if (
      props.order &&
      props.order.status !== OrderStatus.Drafted &&
      !referral.intake_at &&
      props.order.ordered_at
    ) {
      console.log("handleIntakeDaet");
      return updateField(referral, "intake_at", props.order.ordered_at);
    }
    return referral;
  };

  useEffect(() => {
    let result: any[] = [];
    const noSelection = [
      {
        value: "_",
        inputDisplay: txt.get("orders.order.choose_referral"),
      },
    ];

    result = noSelection.concat(
      !clientReferrals || !clientReferrals.map
        ? []
        : clientReferrals.map((referral: Referral) => {
            return {
              value: referral.id + "",
              inputDisplay: txt.get(
                "orders.order.referral_from",
                referral.referred_at
                  ? DateHelper.toDate(referral.referred_at)
                  : txt.get("generic.unknown")
              ),
              dropdownDisplay: (
                <Fragment>
                  <EuiText size="s" style={{ fontWeight: "bold" }}>
                    {referral.referred_at
                      ? DateHelper.toDate(referral.referred_at)
                      : txt.get("generic.unknown")}
                    {referral.orders && referral.orders.length > 0 ? (
                      <EuiText
                        size="xs"
                        color="subdued"
                        style={{ float: "right" }}
                      >
                        PN
                        {referral.orders[0].client_code}
                      </EuiText>
                    ) : (
                      <></>
                    )}
                  </EuiText>
                  <EuiText size="s" color="subdued">
                    <p style={{ whiteSpace: "nowrap" }}>
                      {referral.referrer?.person
                        ? formalName(referral.referrer?.person)
                        : "-"}
                      <br />
                      {referral.referrer?.organisation
                        ? referral.referrer.organisation.name
                        : referral.referrer?.alternative_organisation}
                      <br />
                      {indicationDescription(referral.indications)}
                    </p>
                  </EuiText>
                </Fragment>
              ),
            };
          })
    );

    setClientReferralOptions(result);
  }, [clientReferrals]);

  useEffect(() => {
    setClientReferrals(props.clientReferrals || []);
  }, [props.clientReferrals]);

  const onIntakeAtChanged = (value: Moment | null) => {
    if (value) {
      // This is done because the related column in the database is a timestamp.
      // The function expects a date (yyyy-mm-dd) value from a datepicker.
      // Here, it sets the "time" part to combat timezone confusion.
      value.second(0);
      value.minute(0);
      value.hour(12);
    }

    const intakeAt = MomentHelper.toDate(value);

    const updatedReferral: Referral = handleIntakeDate(
      updateField(referral || REFERRAL_EMPTY, "intake_at", intakeAt)
    );

    if (props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const handleClientReferralOptionChange = (currentOption: string) => {
    let referral: Referral | undefined =
      currentOption === "_"
        ? undefined
        : clientReferrals.find(
            (referral: Referral) => referral.id === parseInt(currentOption)
          );

    console.log("referral", { currentOption, referral });

    if (referral) {
      delete referral.orders;
    }
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(
            {
              ...ORDER_EMPTY,
              ...props.order,
            },
            "referral_id",
            referral ? referral.id : null
          ),
          "referral",
          referral
        )
      );
    } else {
      setReferral(referral);
    }

    setSelectedClientReferralOption(currentOption);
  };

  const handleUsageTermChanged = (value: boolean) => {
    const updatedReferral: Referral = updateField(
      referral || REFERRAL_EMPTY,
      "within_previous_usage_term",
      value
    );

    if (props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const renderIntake = (referralInput?: Input | null) => {
    return (
      <EuiFlexGrid>
        {referralInput ? (
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            {AuthenticationHelper.isInternalUser() ? (
              <EuiFlexItem grow={false}>
                <EuiFormRow
                  display="rowCompressed"
                  label={txt.get("orders.order.intake.name")}
                >
                  <EuiDatePicker
                    compressed={true}
                    showTimeSelect={false}
                    dateFormat="DD-MM-YYYY"
                    selected={
                      referral?.intake_at
                        ? MomentHelper.toMoment(referral.intake_at)
                        : null
                    }
                    placeholder={txt.get("orders.order.intake_at")}
                    onChange={(value) => {
                      onIntakeAtChanged(value ? value : null);
                    }}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            ) : (
              <></>
            )}
            <EuiFlexItem grow={true}>
              {/* {AuthenticationHelper.isInternalUser() ? (
                <EuiFormRow display="rowCompressed">
                  <EuiCheckbox
                    compressed={true}
                    id="referral-within-previous-usage-term"
                    onChange={(e: any) => {
                      handleUsageTermChanged(e.target.checked);
                    }}
                    checked={!!referral?.within_previous_usage_term}
                    value={1}
                    label={
                      <MMTip
                        text={txt.html(
                          "orders.order.within_previous_usage_term_explanation"
                        )}
                      >
                        {txt.get("orders.order.within_previous_usage_term")}
                      </MMTip>
                    }
                  />
                </EuiFormRow>
              ) : (
                <></>
              )} */}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {AuthenticationHelper.isInternalUser() &&
              !!props.order?.referral_id ? (
                <EuiToolTip
                  content={txt.get("orders.order.intake.new_referral")}
                >
                  <EuiIcon
                    style={{ marginTop: "17px" }}
                    onClick={(e: any) => {
                      clearReferral();
                    }}
                    size="s"
                    color="text"
                    type="cross"
                  ></EuiIcon>
                </EuiToolTip>
              ) : (
                <></>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {AuthenticationHelper.isInternalUser() &&
              clientReferralOptions.length > 1 ? (
                <EuiFormRow
                  className="product-input"
                  display="rowCompressed"
                  label={<br />}
                >
                  <EuiSuperSelect
                    isLoading={isLoading}
                    compressed={true}
                    color="accent"
                    fullWidth
                    hasDividers={true}
                    name={txt.get("orders.order.choose_referral")}
                    placeholder={txt.get("orders.order.choose_referral")}
                    options={clientReferralOptions}
                    valueOfSelected={selectedClientReferralOption}
                    onChange={(value) =>
                      handleClientReferralOptionChange(value)
                    }
                  />
                </EuiFormRow>
              ) : (
                <></>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <></>
        )}
      </EuiFlexGrid>
    );
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        {renderIntake(getOrderInput("referral", props.inputs))}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderIntake;
