import MMPage from "components/layouts/page/page";
import {
  EuiButton,
  EuiComboBox,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiNotificationBadge,
  EuiPanel,
  EuiSpacer,
  EuiSwitch,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useNavigate } from "react-router-dom";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";
import MMOrdersShortlist, {
  MMOrderShortlistFilters,
} from "components/orders/order-shortlist";
import { ReferralStatus } from "store/data/referral/referral";
import {
  PersonalDetails,
  fullName,
} from "store/data/personal-details/personal-details";
import ConnectAPIHelper from "api/connect-api-helper";
import { log } from "console";
import UrlHelper from "helpers/url-helper";
import {
  START_ORDER_VALUE_CONNECT,
  totalOrderCount,
} from "store/data/order/order-count";
import { OrganisationOrdering } from "store/data/organisation/organisation";
import { OrderStatus } from "store/data/order/order";
import { Insurer } from "store/data/insurer/insurer";
import { useLocalStorage } from "store/local-storage";
import { toSelectOptions } from "hoc/helper-hooks";

const MAIN_PRACTITIONER_IDS = env("REACT_APP_MAIN_PRACTITIONER_IDS").split(",");

function MMAuthorizations() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [selectedPendingTab, setSelectedPendingTab] = useState(
    UrlHelper.queryParam("authorizations_pending_tab")
  );
  const [selectedPractitionerTab, setSelectedPractitionerTab] = useState(
    UrlHelper.queryParam("authorizations_practitioner_tab")
  );
  const [onlyShowComplete, setOnlyShowComplete] = useState(
    UrlHelper.queryParam("authorizations_only_show_complete") === "true"
      ? true
      : false
  );

  const [orderCounts, setOrderCounts] = useState<any[]>([]);
  const [mainPractitioners, setMainPractitioners] = useState<PersonalDetails[]>(
    []
  );

  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [selectedInsurers, setSelectedInsurers] = useLocalStorage(
    "authorization_insurers",
    []
  );

  const onInsurerChange = (selectedOptions: any[]) => {
    setSelectedInsurers(selectedOptions);
  };

  const paymentInfoExpectedStatusses = [
    OrderStatus.Drafted,
    OrderStatus.Submitted,
    OrderStatus.Accepted,
    // OrderStatus.Rejected,
    OrderStatus.Produced,
    OrderStatus.ProducedPartial,
    OrderStatus.Packaged,
    OrderStatus.PackagedPartial,
    OrderStatus.Returned,
    OrderStatus.Delivered,
    OrderStatus.Finalized,
  ];
  const paymentInfoReferralStatusses = [
    ReferralStatus.Submitted,
    ReferralStatus.Accepted,
    ReferralStatus.NotApplicable,
  ];
  const loadOrderCounts = async () => {
    const result = await api.getOrderCounts(
      ["practitioner"],
      undefined,
      START_ORDER_VALUE_CONNECT,
      [OrganisationOrdering.Internal, OrganisationOrdering.External]
    );
    setOrderCounts(result);
  };

  useEffect(() => {
    let params: any = {
      authorizations_practitioner_tab: selectedPractitionerTab
        .toString()
        .toLowerCase(),
      authorizations_pending_tab: selectedPendingTab.toString().toLowerCase(),
      authorizations_only_show_complete: onlyShowComplete ? "true" : "false",
    };
    const orderParam = UrlHelper.queryParam("order");
    if (orderParam) {
      params.order = orderParam;
    }
    window.history.replaceState(
      null,
      txt.get("finance.authorizations.page_title"),
      `/finance/authorizations?${UrlHelper.toQueryString(params)}`
    );

    loadOrderCounts();
  }, [selectedPendingTab, selectedPractitionerTab, onlyShowComplete]);

  useEffect(() => {
    const load = async () => {
      const result = await api.getPractitioners(
        [AuthenticationHelper.getInternalOrganisationId()],
        MAIN_PRACTITIONER_IDS
      );
      setMainPractitioners(result);

      const insurers = await api.getAdminInsurers(
        undefined,
        undefined,
        "name",
        "asc"
      );
      setInsurers(insurers.result);

      loadOrderCounts();
    };

    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("finance.page_title")}
            size="s"
            style={{ width: "40px", minInlineSize: "40px" }}
            iconType="stats"
            onClick={() => navigate("/finance")}
          />
        );
      }

      if (await AuthenticationHelper.hasPermission(["referrals#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/referrals")}>
            {txt.get("referrals.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/order-values")}>
            {txt.get("finance.order_values.page_title")}{" "}
            {/* <EuiNotificationBadge color="accent">2</EuiNotificationBadge> */}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton
            fill={true}
            size="s"
            onClick={() => navigate("/finance/authorizations")}
          >
            {txt.get("finance.authorizations.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        // if (buttons.length > 0) {
        //   buttons.push(<EuiIcon type="arrowRight" key="chevron3" />);
        // }
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/declarations")}>
            {txt.get("finance.declarations.page_title")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
    load();
  }, []);

  useEffect(() => {
    const currentPractitionerTab = UrlHelper.queryParam(
      "authorizations_practitioner_tab"
    );

    if (currentPractitionerTab !== "") {
      setSelectedPractitionerTab((current: any) => currentPractitionerTab);
    } else {
      setSelectedPractitionerTab((current: any) =>
        mainPractitioners.length > 0
          ? mainPractitioners[0].id?.toString().toLowerCase()
          : ""
      );
    }

    const currentPendingTab = UrlHelper.queryParam(
      "authorizations_pending_tab"
    );

    if (currentPendingTab !== "") {
      setSelectedPendingTab((current: any) => currentPendingTab);
    } else {
      setSelectedPendingTab((current: any) => "clarify");
    }
  }, [mainPractitioners]);

  const renderOrderValuesTabs = () => {
    const tabs: ("clarify" | "quote" | "authorization" | "declined")[] = [
      "clarify",
      "quote",
      "authorization",
      "declined",
    ];

    function getFiltersForTabName(
      tabName: "clarify" | "quote" | "authorization" | "declined"
    ) {
      // Global filters for all tabs in this authorization view.
      let filters: any = {
        referral_status: paymentInfoReferralStatusses,
        status: paymentInfoExpectedStatusses,
        needs_payment_info: [true],
        has_payment_info: [true],
        is_authorized: [false],
      };

      if (onlyShowComplete) {
        filters.is_complete = [true];
      }

      switch (tabName) {
        case "clarify":
          return {
            ...filters,
            // Orders which have an ambiguous authorization requirement.
            needs_authorization: ["unknown"],
            is_quoted: [false],
          };

        case "quote":
          return {
            ...filters,
            // Orders which needs to go through the quotation process.
            needs_authorization: ["yes"],
            is_quoted: [false],
          };

        case "authorization":
          return {
            ...filters,
            // Orders that have gone through the quotation process and are
            // now waiting for a reply from the authorization authority.
            is_quoted: [true],
            is_declined: [false],
          };
        case "declined":
          return {
            ...filters,
            // Orders that have gone through the quotation process and are
            // declined by the authorization authority.
            is_quoted: [true],
            is_declined: [true],
          };

        default:
          return filters;
      }
    }

    return (
      <EuiTabs>
        {tabs.map(
          (tabName: "clarify" | "quote" | "authorization" | "declined") => (
            <EuiTab
              key={`tab-${tabName}`}
              onClick={() => setSelectedPendingTab(tabName)}
              isSelected={selectedPendingTab === tabName}
              append={
                <EuiNotificationBadge
                  color={tabName === "declined" ? "accent" : "subdued"}
                >
                  {totalOrderCount(orderCounts, [
                    getFiltersForTabName(tabName),
                  ])}
                </EuiNotificationBadge>
              }
            >
              <span>{txt.get(`finance.authorizations.${tabName}`)}</span>
            </EuiTab>
          )
        )}
      </EuiTabs>
    );
  };

  const renderOrderValuesTab = () => {
    const insurers: number[] = selectedInsurers.map(
      (option: any) => option.value
    );
    let filters: MMOrderShortlistFilters = {
      // Order value is not a thing before February 2024
      since: "2024-02-01",

      // Global filters for all tabs in this authorization view.
      referralStatus: paymentInfoReferralStatusses,
      status: paymentInfoExpectedStatusses,
      needsPaymentInfo: true,
      hasPaymentInfo: true,
      isAuthorized: false,
      isDeclined: false,
      insurers: insurers,
      ordering: [OrganisationOrdering.Internal, OrganisationOrdering.External],
      // Global sorting scheme for all tabs in this authorization view.
      sortBy: "updated_at",
      sortOrder: "desc",
    };

    if (onlyShowComplete) {
      filters.isComplete = true;
    }

    if (selectedPendingTab === "clarify") {
      // Orders which needs to go through the quotation process.
      filters.needsAuthorization = "unknown";
      filters.isQuoted = false;
    }

    if (selectedPendingTab === "quote") {
      // Orders which needs to go through the quotation process.
      filters.needsAuthorization = "yes";
      filters.isQuoted = false;
    }

    if (selectedPendingTab === "authorization") {
      // Orders that have gone through the quotation process and are
      // now waiting for a reply from the authorization authority.
      filters.isQuoted = true;
      filters.isDeclined = false;
    }
    if (selectedPendingTab === "declined") {
      // Orders that have gone through the quotation process and are
      // now having a negative reply from the authorization authority.
      filters.isQuoted = true;
      filters.isDeclined = true;
    }

    const columns = [
      "order_id",
      "client_code",
      "status",
      "is_complete",
      "practitioner",
      "products",
      "ordered_at",
      "last_update",
    ];

    if (
      selectedPendingTab === "authorization" ||
      selectedPendingTab === "declined"
    ) {
      columns.push("last_quoted_at");
    }

    return selectedPendingTab ? (
      <EuiPanel key={`authorizations-${selectedPendingTab}`} grow={false}>
        <MMOrdersShortlist
          filters={filters}
          columns={columns}
          clickable={true}
          name={`authorizations_${selectedPendingTab}`}
          orderTab="costing"
        />
      </EuiPanel>
    ) : (
      <></>
    );
  };

  return (
    <MMPage
      title={txt.get("finance.authorizations.page_title")}
      hideTitle={true}
      topActions={buttonsToShow}
    >
      <EuiFlexItem>
        <EuiSpacer size="xl" />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFlexGrid>
              <EuiFlexItem>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <EuiIcon type="documentation" size="l" />
                  <EuiTitle>
                    <EuiText>
                      {txt.get("finance.authorizations.incoming")}
                      <span style={{ fontSize: "1rem" }}>
                        {" "}
                        ({txt.get("finance.authorizations.no_b2b")})
                      </span>
                    </EuiText>
                  </EuiTitle>
                </div>{" "}
                <EuiSpacer />
                <EuiFlexGroup alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiComboBox
                      compressed={true}
                      style={{ minWidth: "220px", width: "100%" }}
                      aria-label={txt.uf(
                        "generic.select_x",
                        txt.get("admin.insurers.name")
                      )}
                      placeholder={txt.uf(
                        "generic.select_x",
                        txt.get("admin.insurers.name")
                      )}
                      // singleSelection={{ asPlainText: true }}
                      options={[
                        {
                          label: txt.get(
                            "finance.authorizations.missing_contracts"
                          ),
                          value: "null",
                        },
                        ...toSelectOptions(insurers),
                      ]}
                      selectedOptions={selectedInsurers}
                      onChange={onInsurerChange}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiSwitch
                      onChange={(e: any) =>
                        setOnlyShowComplete(!onlyShowComplete)
                      }
                      label={txt.get("finance.only_complete")}
                      checked={onlyShowComplete}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="s" />
                {renderOrderValuesTabs()}
                <EuiSpacer />
                {renderOrderValuesTab()}
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMAuthorizations;
