import {
  EuiButtonIcon,
  EuiCheckbox,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { MMOrderEntryInputProps } from "../order-entry";
import { ReactNode, useEffect, useState } from "react";
import { ORDER_EMPTY } from "store/data/order/order";
import { NEED_EMPTY, Need, getNeed, hasNeed } from "store/data/need/need";
import { Input } from "helpers/input-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import MMAITextToSentence from "components/layouts/aitools/text-to-sentence";
import env from "helpers/env-helper";
import { Feature, feat } from "feats";
import MMTip from "components/layouts/tip/tip";
import { toSelectOptions } from "hoc/helper-hooks";
import MMIcon, { MMIconType } from "components/layouts/icon/icon";

const LEGACY_MAPPING_TEXTS: any = {
  "usage.explanation": ["care_needs.activities", "usage.other"],
  "characteristics.explanation": ["anatomy.other", "characteristics.other"],
  "care_needs.explanation": ["care_needs.needs"],
};
const LEGACY_MAPPING: any = {
  "care_needs.pain": "anatomy.pain",
  "care_needs.strength": "anatomy.strength",
  "care_needs.mobility": "anatomy.mobility",
  "care_needs.sensibility": "anatomy.sensibility",
};
const TEXTAREA_SIZE_SMALL: number = 2;
const TEXTAREA_SIZE_NORMAL: number = 3;
const TEXTAREA_SIZE_LARGE: number = 6;
const enum ValueType {
  Boolean = "BOOLEAN",
  String = "STRING",
}

function MMOrderNeeds(props: MMOrderEntryInputProps) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const [needs, setNeeds] = useState<Need[]>([]);
  const [needTags, setNeedTags] = useState<any>({});
  const [isCharacteristicsEnabled, setIsCharacteristicsEnabled] =
    useState<boolean>(true);
  const [isUsageEnabled, setIsUsageEnabled] = useState<boolean>(true);
  const [isTextToSentenceShown, setIsTextToSentenceShown] =
    useState<boolean>(false);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsTextToSentenceShown(
        await AuthenticationHelper.hasPermission([
          "contracts#read_all",
          "contracts#edit_all",
        ])
      );
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    const handleNeeds = async (needs: Need[]) => {
      setNeeds((old: Need[]) => needs);
      setIsCharacteristicsEnabled(
        getNeed(needs, "characteristics.none") !== "true"
      );
      setIsUsageEnabled(getNeed(needs, "usage.none") !== "true");
      if (
        !needTags ||
        !needTags?.["activities.activities"] ||
        !needTags?.["activities.hobbies"]
      ) {
        const activityTags = await api.getNeedTags("activities.activities");
        const hobbyTags = await api.getNeedTags("activities.hobbies");
        setNeedTags((old: any) => ({
          "activities.activities": activityTags,
          "activities.hobbies": hobbyTags,
        }));
      }
    };
    if (props.order && props.order.needs) {
      handleNeeds(props.order.needs);
    } else {
      setNeeds((old: Need[]) => []);
      setNeedTags((old: any) => {});
    }
  }, [props.order?.needs]);

  const onNeedsChanged = (
    updatedNeeds: Need[],
    updateOrder: boolean = true
  ) => {
    if (props.onChange && updateOrder) {
      props.onChange({
        ...ORDER_EMPTY,
        ...props.order,
        needs: updatedNeeds,
      });
    } else {
      setIsCharacteristicsEnabled(
        getNeed(updatedNeeds, "characteristics.none") !== "true"
      );
      setIsUsageEnabled(getNeed(updatedNeeds, "usage.none") !== "true");

      setNeeds((needs: Need[]) => updatedNeeds);
    }
  };

  const onNeedChange = (
    question: string,
    answer: string,
    updateOrder: boolean = true,
    subNeeds: string[] = []
  ) => {
    let updatedNeeds: Need[] = [];
    let isNeedFound: boolean = false;

    for (let i = 0; i < needs.length; i++) {
      const need: Need = needs[i];
      if (need.question === question || subNeeds.includes(need.question)) {
        isNeedFound = true;
        if (answer !== "false") {
          updatedNeeds.push({ ...need, answer });
        }
      } else if (
        (question === "characteristics.none" &&
          need.question.startsWith("characteristics")) ||
        (question === "usage.none" && need.question.startsWith("usage"))
      ) {
      } else {
        updatedNeeds.push({ ...need });
      }

      // if (need.question === question || subNeeds.includes(need.question)) {
      //   isNeedFound = true;
      // }

      // if (isNeedFound && answer !== "false") {
      //   updatedNeeds.push({ ...need, answer });
      // } else if (
      //   (question === "characteristics.none" &&
      //     need.question.startsWith("characteristics")) ||
      //   (question === "usage.none" && need.question.startsWith("usage"))
      // ) {
      //   //don't add needs anymore if the question is a 'none' question
      // } else if (!isNeedFound) {
      //   updatedNeeds.push({ ...need });
      // }
    }
    if (!isNeedFound) {
      updatedNeeds.push({ ...NEED_EMPTY, question, answer });
    }

    onNeedsChanged(updatedNeeds, updateOrder);
  };

  const errorMessage = (question: string) => {
    if (!props.hasValidation) return "";

    const fields: any = { ...props.inputs?.needs };
    const input: Input | null = fields[question]
      ? (fields[question] as Input)
      : null;
    if (!input?.mandatory) return "";

    if (
      props.order &&
      props.order?.needs.findIndex(
        (need: Need) => need.question === question && !!need.answer
      ) >= 0
    ) {
      return "";
    }

    return txt.get("validations.not_empty");
  };

  const valueOfNeed = (question: string, valueType: ValueType) => {
    const need: Need | undefined = needs.find(
      (need: Need) => need.question === question
    );
    if (need) {
      return valueType === ValueType.Boolean
        ? need.answer === "true"
        : need.answer;
    }
    return valueType === ValueType.Boolean ? false : "";
  };

  const renderTitle = (
    questions: string,
    tipText?: string | ReactNode,
    icon?: MMIconType
  ) => (
    <EuiFlexItem>
      <EuiTitle size="xs">
        <EuiText color="grey">
          {tipText ? (
            <MMTip text={tipText}>
              {icon ? (
                <span style={{ paddingRight: "8px" }}>
                  <MMIcon type={icon} />
                </span>
              ) : (
                <></>
              )}
              {txt.get(`needs.questions.${questions}.title`)}
            </MMTip>
          ) : (
            txt.get(`needs.questions.${questions}.title`)
          )}
        </EuiText>
      </EuiTitle>
    </EuiFlexItem>
  );

  const renderCheckNeed = (
    question: string,
    moreText?: string,
    padded: boolean = false,
    subNeeds: string[] = [],
    isEnabled: any = true
  ) => {
    const error: string = errorMessage(question);
    let isChecked: boolean = valueOfNeed(
      question,
      ValueType.Boolean
    ) as boolean;

    let oldChecked: string = "";
    let newChecked: string = "";
    //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07
    let newQuestions: string[] = Object.keys(LEGACY_MAPPING);
    if (newQuestions.includes(question)) {
      oldChecked = getNeed(needs, LEGACY_MAPPING[question]);
      newChecked = getNeed(needs, question);
      if (
        newChecked === "" &&
        (oldChecked === "true" || oldChecked === "false")
      ) {
        isChecked = oldChecked === "true";
      }
    }

    return (
      <EuiFlexItem>
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          style={padded ? { marginLeft: "25px" } : {}}
          isInvalid={!!error}
          error={error}
          isDisabled={!isEnabled || !props.isEditable}
        >
          <EuiCheckbox
            disabled={!isEnabled || !props.isEditable}
            readOnly={!isEnabled || !props.isEditable}
            compressed={true}
            id={question}
            name={question}
            label={
              moreText ? (
                <EuiToolTip content={txt.get(`needs.questions.${moreText}`)}>
                  <EuiText size="s" style={{ position: "relative" }}>
                    {txt.html(`needs.questions.${question}`)}
                    <span style={{ position: "absolute" }}>
                      <EuiIcon color="#AAAAAA" size="s" type="iInCircle" />
                    </span>
                  </EuiText>
                </EuiToolTip>
              ) : (
                <EuiText size="s">
                  <EuiText size="s">
                    {txt.html(`needs.questions.${question}`)}
                  </EuiText>
                </EuiText>
              )
            }
            checked={isChecked}
            onChange={(e) => {
              onNeedChange(
                question,
                e.target.checked ? "true" : "false",
                true,
                !e.target.checked ? subNeeds : []
              );
            }}
          />
        </EuiFormRow>
        <EuiSpacer size="xs" />
      </EuiFlexItem>
    );
  };

  const onCreateOption = (question: string, value: string) => {
    const normalizedSearchValue = value.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const needValue: string = valueOfNeed(question, ValueType.String) as string;

    const options = toSelectOptions(
      needValue.split(",").filter((value: string) => value !== "")
    );

    if (
      options.findIndex(
        (option: any) =>
          option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setNeedTags({
        [question]: [
          ...(needTags && needTags[question] ? needTags[question] : []),
          normalizedSearchValue,
        ],
      });
      onNeedChange(question, `${needValue},${normalizedSearchValue}`);
    }
  };

  const renderTagsNeed = (question: string, title: string = "") => {
    const error: string = errorMessage(question);

    let tagOptions = needTags[question] ? needTags[question] : [""];
    let tagOptionsSelected = (valueOfNeed(question, ValueType.String) as string)
      .split(",")
      .filter((value: string) => value !== "");

    let options = toSelectOptions(tagOptions);
    let selectedOptions = toSelectOptions(tagOptionsSelected);

    //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07
    // if (question === "activities.activities") {
    //   let legacyActivities: string = getNeed(needs, "care_needs.activities");
    //   if (
    //     legacyActivities !== "" &&
    //     !tagOptionsSelected.includes(legacyActivities)
    //   ) {
    //     // options = toSelectOptions([legacyActivities], {
    //     //   color: "#EFEFEF",
    //     // }).concat(options);
    //     selectedOptions = toSelectOptions([legacyActivities], {
    //       color: "#EFEFEF",
    //     }).concat(selectedOptions);
    //   }
    // }

    return (
      <EuiFlexItem
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          isInvalid={!!error}
          error={error}
          isDisabled={!props.isEditable}
          title={title}
          label={
            title ? (
              <EuiText size="s" color="text" style={{ fontWeight: 600 }}>
                {title}
              </EuiText>
            ) : (
              ""
            )
          }
        >
          <EuiComboBox
            className="needs-combo-box"
            compressed={true}
            aria-label={txt.get(`needs.questions.${question}`)}
            placeholder={txt.get(`needs.questions.${question}`)}
            options={options}
            selectedOptions={selectedOptions}
            onChange={(selected: any) =>
              onNeedChange(
                question,
                selected.map((value: any) => value.value).join(",")
              )
            }
            onCreateOption={(searchValue) =>
              onCreateOption(question, searchValue)
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
    );
  };

  const renderTextNeed = (
    question: string,
    rows: number = TEXTAREA_SIZE_NORMAL,
    toSentenceIntent?: "need" | "descriptive" | "motivation",
    isEnabled: boolean = true,
    padded: boolean = false
  ) => {
    const error: string = errorMessage(question);

    let value: string = valueOfNeed(question, ValueType.String) as string;
    let oldText: string = "";
    //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07
    let newQuestions: string[] = Object.keys(LEGACY_MAPPING_TEXTS);
    if (newQuestions.includes(question) && !hasNeed(needs, question)) {
      oldText = (
        typeof LEGACY_MAPPING_TEXTS[question] === "string"
          ? [LEGACY_MAPPING_TEXTS[question]]
          : LEGACY_MAPPING_TEXTS[question]
      )
        .map((mapped: string) => getNeed(needs, mapped))
        .filter((answer: string) => answer !== "")
        .join("; ");

      if (oldText !== "") {
        value = oldText;
      }
    }

    let style: any = {
      position: "relative",
      width: "100%",
    };
    if (padded) {
      style.marginLeft = "25px";
      style.width = "calc(100% - 25px)";
    }
    return (
      <EuiFlexItem style={style}>
        <EuiFormRow
          display="rowCompressed"
          // fullWidth={true}
          isInvalid={!!error}
          error={error}
          isDisabled={!isEnabled || !props.isEditable}
          // className={!props.isEditable ? "readonly-input" : ""}
        >
          <EuiTextArea
            className="needs-text-area"
            disabled={!isEnabled || !props.isEditable}
            readOnly={!props.isEditable}
            rows={rows}
            isInvalid={!!error}
            cols={40}
            fullWidth={true}
            onChange={(e: any) => onNeedChange(question, e.target.value, false)}
            onBlur={(e: any) => onNeedChange(question, e.target.value)}
            value={value}
            placeholder={txt.get(`needs.questions.${question}`)}
          />
        </EuiFormRow>
        {isEnabled && isTextToSentenceShown ? (
          <MMAITextToSentence
            style={{ position: "absolute", top: "5px", right: "15px" }}
            text={valueOfNeed(question, ValueType.String) as string}
            intent={toSentenceIntent}
            onResult={(sentence: string) => {
              onNeedChange(question, sentence);
            }}
          />
        ) : (
          <></>
        )}
        <EuiSpacer size="s" />
      </EuiFlexItem>
    );
  };

  const renderGroupedNeedsError = (key: string) => {
    if (!props.hasValidation) return "";

    let isValid: boolean = true;

    const keys: string[] = props.inputs?.needs
      ? Object.keys(props.inputs?.needs)
      : [];

    if (
      props.order &&
      keys.includes(key) &&
      props.order.needs.findIndex(
        (need: Need) =>
          need.question.startsWith(key) &&
          need.answer !== "" &&
          need.answer !== "false"
      ) < 0
    ) {
      //if there is no need that is not false or empty, nothing is filledout
      isValid = false;
    }

    return !isValid ? (
      <EuiText size="s" color="danger">
        {txt.get("validations.any_not_empty_choose")}
      </EuiText>
    ) : (
      <></>
    );
  };
  const isNeed = (question: string, answer?: string) => {
    const need: Need | undefined = needs.find(
      (need: Need) => need.question === question
    );
    return need && (answer === undefined || need.answer === answer);
  };

  return (
    <EuiFlexGroup
      gutterSize="xl"
      // className={
      //   props.order?.order_lines && props.order.order_lines.length > 0
      //     ? "guttersize-xxl with-products"
      //     : "guttersize-xxl"
      // }
      // justifyContent="center"
      alignItems="flexStart"
    >
      <EuiFlexItem grow={true} style={{ minWidth: "275px" }}>
        <EuiFlexGroup
          direction="column"
          gutterSize="s"
          justifyContent="flexStart"
          alignItems="flexStart"
        >
          {renderTitle(
            "care_needs",
            txt.html(`orders.order.i.care_needs`),
            "care-need"
          )}
          <EuiHorizontalRule className="rule-strong" margin="none" />
          <EuiSpacer size="xs" />
          {renderCheckNeed("care_needs.pain")}
          {renderCheckNeed("care_needs.mobility")}
          {renderCheckNeed("care_needs.strength")}
          {renderCheckNeed("care_needs.sensibility")}
          {/* {renderCheckNeed("care_needs.other", undefined, false)} */}

          {renderTextNeed(
            "care_needs.explanation",
            TEXTAREA_SIZE_LARGE,
            "descriptive"
          )}
          {renderGroupedNeedsError("care_needs")}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={true} style={{ minWidth: "275px" }}>
        <EuiFlexGroup
          direction="column"
          gutterSize="s"
          justifyContent="flexStart"
          alignItems="flexStart"
        >
          {renderTitle("usage", txt.html(`orders.order.i.usage`), "usage")}
          <EuiHorizontalRule className="rule-strong" margin="none" />
          <EuiSpacer size="xs" />

          {renderTagsNeed(
            "activities.activities",
            txt.get(`needs.questions.activities.adl_acronym`)
          )}
          <EuiSpacer size="xs" />

          {renderTagsNeed(
            "activities.hobbies",
            txt.get(`needs.questions.activities.hobbies_title`)
          )}
          <EuiSpacer size="xs" />
          {renderCheckNeed(
            "usage.load",
            "usage.load_more",
            false,
            [],
            isUsageEnabled
          )}
          {renderCheckNeed(
            "usage.water",
            "usage.water_more",
            false,
            [],
            isUsageEnabled
          )}
          {renderCheckNeed(
            "usage.contra_limited",
            "usage.contra_limited_more",
            false,
            [],
            isUsageEnabled
          )}
          {renderCheckNeed(
            "usage.other_aids",
            "usage.other_aids_more",
            false,
            [],
            isUsageEnabled
          )}

          {/* {renderCheckNeed("usage.other", undefined, false, [], isUsageEnabled)} */}

          {renderTextNeed(
            "usage.explanation",
            TEXTAREA_SIZE_NORMAL,
            "descriptive",
            isUsageEnabled
          )}
          <EuiSpacer size="xs" />
          {renderCheckNeed("usage.none")}
          {renderGroupedNeedsError("usage")}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={true} style={{ minWidth: "275px" }}>
        <EuiFlexGroup
          direction="column"
          gutterSize="s"
          justifyContent="flexStart"
          alignItems="flexStart"
        >
          {renderTitle(
            "characteristics",
            txt.html(`orders.order.i.characteristics`),
            "characteristic"
          )}
          <EuiHorizontalRule className="rule-strong" margin="none" />
          <EuiSpacer size="xs" />

          {renderCheckNeed(
            "characteristics.allergies",
            undefined,
            false,
            [
              "characteristics.allergies_silver",
              "characteristics.allergies_silicone",
              "characteristics.allergies_nylon",
            ],
            isCharacteristicsEnabled
          )}
          {isNeed("characteristics.allergies", "true") ? (
            renderCheckNeed(
              "characteristics.allergies_silver",
              undefined,
              true,
              [],
              isCharacteristicsEnabled
            )
          ) : (
            <></>
          )}
          {isNeed("characteristics.allergies", "true") ? (
            renderCheckNeed(
              "characteristics.allergies_silicone",
              undefined,
              true,
              [],
              isCharacteristicsEnabled
            )
          ) : (
            <></>
          )}
          {isNeed("characteristics.allergies", "true") ? (
            renderCheckNeed(
              "characteristics.allergies_nylon",
              undefined,
              true,
              [],
              isCharacteristicsEnabled
            )
          ) : (
            <></>
          )}
          {renderCheckNeed(
            "characteristics.skin_conditions",
            undefined,
            false,
            [],
            isCharacteristicsEnabled
          )}
          {renderCheckNeed(
            "characteristics.sweating",
            undefined,
            false,
            [],
            isCharacteristicsEnabled
          )}
          {renderCheckNeed(
            "characteristics.abnormalities",
            undefined,
            false,
            [],
            isCharacteristicsEnabled
          )}
          {isNeed("characteristics.abnormalities", "true") ? (
            renderTextNeed(
              "characteristics.abnormalities_explanation",
              TEXTAREA_SIZE_SMALL,
              "descriptive",
              isCharacteristicsEnabled,
              true
            )
          ) : (
            <></>
          )}
          {/* {renderCheckNeed(
            "characteristics.other",
            undefined,
            false,
            [],
            isCharacteristicsEnabled
          )} */}

          {renderTextNeed(
            "characteristics.explanation",
            TEXTAREA_SIZE_NORMAL,
            "descriptive",
            isCharacteristicsEnabled
          )}
          <EuiSpacer size="xs" />
          {renderCheckNeed("characteristics.none")}
          {renderGroupedNeedsError("characteristics")}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderNeeds;
